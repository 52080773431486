<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          WeeklyReports
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select v-model="query.grade" placeholder="Grade" class="mr10" :clearable="true">
          <el-option v-for="item in options.gradeList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="query.term" placeholder="Term" class="mr10" :clearable="true">
          <el-option v-for="item in options.termList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-input v-model="query.name" placeholder="Report Name" class="handle-input mr10" @keyup.enter="handleSearch"
          :clearable="true"></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Create a WeeklyReport
        </el-button>
      </div>
      <el-tabs v-model="reportStatusActiveName" :before-leave="handleReportStatusTabChange">
        <el-tab-pane label="Active" name="activeReport"></el-tab-pane>
        <el-tab-pane label="Finished" name="finishedReport"></el-tab-pane>
      </el-tabs>
      <el-table :data="getTableData()" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @sort-change="changeSort" :default-sort="{ prop: 'UpdatedTime', order: 'descending' }">
        <el-table-column prop="name" label="Report Name" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="Name"></el-table-column>
        <el-table-column prop="roomName" label="Room Name" :width="this.$widthRatio * 240 + 'px'"></el-table-column>
        <el-table-column prop="grade" label="Grade" :width="this.$widthRatio * 80 + 'px'" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="Grade"></el-table-column>
        <el-table-column prop="term" label="Term" :width="this.$widthRatio * 80 + 'px'"></el-table-column>
        <el-table-column label="Status" :width="this.$widthRatio * 120 + 'px'">
          <template #default="scope">
            <el-tag class="ml-2" :type="showWeeklyReportStatusTagType(scope.$index, scope.row)">
              {{ scope.row.status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="updatedTime" sortable="true" :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime" label="Updated Time" :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"></el-table-column>
        <el-table-column label="Operation" :width="this.$widthRatio * 200 + 'px'" align="center">
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button type="text" @click="manageWeeklyRecords('quiz', scope.$index, scope.row)">
              <el-icon>
                <ticket />
              </el-icon>
              Quiz
            </el-button>
            <el-button type="text" @click="manageWeeklyRecords('homework', scope.$index, scope.row)">
              <el-icon>
                <ticket />
              </el-icon>
              Homework
            </el-button>
            <el-button type="text" v-if="scope.row.status !== 'Published'"
              @click="publishReport(scope.$index, scope.row)">
              <el-icon>
                <cloudy />
              </el-icon>
              Publish
            </el-button>
            <el-button v-if="scope.row.status === 'Published'" type="text" class="red" @click="
              issuePoints(scope.$index, scope.row, 'homework', 'preview')
              ">
              <el-icon>
                <coin />
              </el-icon>
              Issue Points for HW
            </el-button>
            <el-button v-if="scope.row.status === 'Published'" type="text" class="red"
              @click="issuePoints(scope.$index, scope.row, 'quiz', 'preview')">
              <el-icon>
                <coin />
              </el-icon>
              Issue Points for Quiz
            </el-button>
            <el-button type="text" @click="showWeeklyReport(scope.$index, scope.row, 'quiz')">
              <el-icon>
                <histogram />
              </el-icon>
              Quiz Report
            </el-button>
            <el-button type="text" @click="showWeeklyReport(scope.$index, scope.row, 'homework')">
              <el-icon>
                <histogram />
              </el-icon>
              Homework Report
            </el-button>
            <el-button type="text" class="red" @click="handleDelete(scope.$index, scope.row)">
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="this.reportStatusActiveName === 'finishedReport'">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
          :page-size="query.pageSize" :total="query.itemTotal" :page-count="query.pageTotal"
          @current-change="handlePageChange"></el-pagination>
      </div>

      <el-dialog :title="dialogTitle" v-model="editVisible" :lock-scroll="false" :append-to-body="true"
        :close-on-click-modal="false" width="90%">
        <el-form ref="mainForm" :model="form" :rules="rules" :label-width="this.$widthRatio * 120 + 'px'">
          <el-form-item prop="room" label="ClassRoom">
            <el-select v-model="form.roomId" placeholder="Please select..." @change="handleClassRoomChange">
              <el-option v-for="item in options.classRoomList" :key="item.roomId" :label="item.name"
                :value="item.roomId"
                :style="`background-color:${item.activeStatus === 1024 ? this.unstartBGColor : item.activeStatus === 2048 ? this.activeBGColor : '#FFF'};`"></el-option>
            </el-select>
            <div
              :style="`margin-left:10px;padding:0 5px;display: inline-block;background-color: ${this.activeBGColor};`">
              Active</div>
            <div
              :style="`margin-left:10px;padding:0 5px;display: inline-block;background-color: ${this.unstartBGColor};`">
              Unstarted
            </div>
          </el-form-item>
          <el-form-item prop="name" label="Report Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item prop="grade" label="Grade">
            <el-select :disabled="true" v-model="form.grade" placeholder="Please select...">
              <el-option v-for="item in options.gradeList" :key="item.value" :label="item.description"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="term" label="Term">
            <el-select :disabled="true" v-model="form.term" placeholder="Please select...">
              <el-option v-for="item in options.termList" :key="item.value" :label="item.description"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Quiz Raw Score">
            <el-input-number v-model="form.quizW1RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 1" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW2RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 2" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW3RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 3" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW4RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 4" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW5RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 5" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW6RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 6" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW7RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 7" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW8RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 8" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW9RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 9" class="mr10"></el-input-number>
            <el-input-number v-model="form.quizW10RawScore" :precision="0" :step="5" :min="0" :max="100"
              :controls="false" placeholder="Week 10" class="mr10"></el-input-number>
          </el-form-item>
          <el-form-item prop="status" label="Status">
            <el-select v-model="form.status" placeholder="Please select...">
              <el-option label="Published" value="Published"></el-option>
              <el-option label="Finished" value="Finished"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-tabs v-model="this.activeRecordType" :lazy="true" v-if="form.records"
          :before-leave="handleRecordTypeTabChange">
          <el-tab-pane label="Quiz" name="quiz">
            <manage-weekly-records recordType="quiz" :reportData="form" :recordList="form.records['quiz']" />
          </el-tab-pane>
          <el-tab-pane label="Homework" name="homework">
            <manage-weekly-records recordType="homework" :reportData="form" :recordList="form.records['homework']" />
          </el-tab-pane>
        </el-tabs>

        <div class="el-dialog__footer">
          <el-button @click="editVisible = false">Cancel</el-button>
          <el-button type="primary" @click="saveEdit">Save</el-button>
        </div>
      </el-dialog>

      <el-dialog ref="weeklyRecordsDialog" :title="manageWeeklyRecordsDialogTitle"
        v-model="showManageWeeklyRecordsDialog" :lock-scroll="false" :append-to-body="true"
        :close-on-click-modal="false" width="90%">
        <manage-weekly-records :recordType="this.manageRecordType" :reportData="currentReport"
          :recordList="currentReport.records[this.manageRecordType]" :destroy-on-close="true" />
        <div class="el-dialog__footer">
          <el-button @click="showManageWeeklyRecordsDialog = false">
            Cancel
          </el-button>
          <el-button type="primary" @click="saveRecords">Save</el-button>
        </div>
      </el-dialog>

      <el-dialog ref="weeklyRecordReportDialog" :title="weeklyRecordReportDialogTitle"
        v-model="showWeeklyRecordReportDialog" :destroy-on-close="true" width="90%">
        <weekly-report-chart :isForAdmin="true" :reportId="currentReport.reportId" :recordType="this.reportRecordType"
          :destroy-on-close="true" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";
import { WeeklyRecordTypeEnum } from "../../service/models/enums.js";
import ManageWeeklyRecords from "./ManageWeeklyRecords.vue";
import WeeklyReportChart from "../common/WeeklyReportChart.vue";

export default {
  components: { ManageWeeklyRecords, WeeklyReportChart },
  name: "weeklyReportList",
  data() {
    return {
      controllerUrl: "/weeklyReport",
      activeReportQuery: {
        reportId: null,
        roomId: null,
        name: null,
        grade: null,
        term: null,
        status: "Published",
        orderBy: "Grade",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: -1,
        itemTotal: 0,
        pageTotal: 0,
      },
      finishedReportQuery: {
        reportId: null,
        roomId: null,
        name: null,
        grade: null,
        term: null,
        status: "Finished",
        orderBy: "Grade",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
        itemTotal: 0,
        pageTotal: 0,
      },
      query: this.activeReportQuery,
      options: {
        gradeList: [],
        termList: [],
        classRoomList: [],
      },
      activeBGColor: "#73d0ff",
      unstartBGColor: "#9cdb9c",
      reportStatusActiveName: "activeReport",
      activeReportList: [],
      finishedReportList: [],
      form: {},
      activeRecordType: null,
      dialogTitle: "",
      currentReport: null,
      editVisible: false,
      editMode: false,
      showManageWeeklyRecordsDialog: false,
      manageWeeklyRecordsDialogTitle: "",
      reportRecordType: "",
      manageRecordType: "",
      showWeeklyRecordReportDialog: false,
      weeklyRecordReportDialogTitle: "",
      rules: {
        name: [
          {
            required: true,
            message: "Please input the Name",
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: "Please select the Grade",
            trigger: "change",
          },
        ],
        term: [
          {
            required: true,
            message: "Please select the Term",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select the status",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.activeBGColor = this.$appSetting.getGenericValue("CLASSROOM_ACTIVE_BGCOLOR", "#73d0ff")
    this.unstartBGColor = this.$appSetting.getGenericValue("CLASSROOM_UNSTRAT_BGCOLOR", "#9cdb9c")

    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;
    this.activeRecordType = WeeklyRecordTypeEnum.Quiz;

    let roomId = this.$route.query.roomId;
    let createNewReport = this.$route.query.t;
    console.debug("WeeklyReportList.created", roomId);

    if (roomId) {
      this.query.roomId = roomId;

      if (createNewReport === "T") {
        this.loadClassRoomAndStudents(roomId);
      }
    }

    this.loadAllClassRooms();

    this.query = this.activeReportQuery;
    this.loadData(this.query);
  },
  methods: {
    loadData(query) {
      getData(this.controllerUrl, query).then((res) => {
        if (res.result && res.code === "200") {
          let resData = res.result;
          this[`${this.reportStatusActiveName}List`] = resData.list;
          console.log("loadData", this.activeClassRoomList, this.unstartedClassRoomList, this.finishedClassRoomList);
          query.pageIndex = resData.pageIndex;
          query.pageSize = resData.pageSize;
          query.itemTotal = resData.count;
          query.pageTotal = Math.ceil(resData.count / resData.pageSize);
        }
      });
    },
    loadClassRoomAndStudents(roomId) {
      getData(`classRoom/getClassRoomAndStudentsIn/${roomId}`).then((res) => {
        console.log("loadClassRoomAndStudents", res);
        if (res.result && res.code === "200") {
          let resData = res.result;
          this.form = {
            reportId: null,
            name: `Weekly Report for ClassRoom: ${resData.name} (Grade: ${resData.grade}, Term: ${resData.term})`,
            roomId: resData.roomId,
            roomName: resData.name,
            grade: resData.grade,
            term: resData.term,
            status: "Published",
          };
          this.form.records = {};
          for (let item in WeeklyRecordTypeEnum) {
            let recordList = [];
            let recordType = this.$formatter.toLowerFirstChar(item);
            if (resData.studentList && resData.studentList.length > 0) {
              for (let i = 0; i < resData.studentList.length; i++) {
                let student = resData.studentList[i];
                let record = {
                  reportId: null,
                  studentId: student.studentId,
                  studentName: student.studentName,
                  w1: 0,
                  w2: 0,
                  w3: 0,
                  w4: 0,
                  w5: 0,
                  w6: 0,
                  w7: 0,
                  w8: 0,
                  w9: 0,
                  w10: 0,
                  total: 0,
                };
                recordList.push(record);
              }
            }

            this.form.records[recordType] = recordList;
          }
        }
      });
    },
    loadAllClassRooms() {
      getData("classRoom/getAllClassRoomIdAndNameForReceipt", null, {
        isShowLoading: false,
      }).then((res) => {
        let list = res.result;
        list = list.filter((e) => e.activeStatus === 2048)
        list.sort((x, y) => {
          return (x.gradeValue * 10 + x.termValue) - (y.gradeValue * 10 + y.termValue)
        });
        this.options.classRoomList = list;
      });
    },
    handleClassRoomChange(roomId) {
      console.log("handleClassRoomChange", arguments);
      this.loadClassRoomAndStudents(roomId);
    },
    handleSearch() {
      let query = this[`${this.reportStatusActiveName}Query`]
      query.pageIndex = 1;
      this.loadData(query);
    },
    changeSort(sortData) {
      let query = this[`${this.reportStatusActiveName}Query`]
      if (sortData.order) {
        query.orderBy = sortData.prop;
        query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        query.orderBy = "";
        query.orderDirection = "";
      }
      this.loadData(query);
    },
    handlePageChange(val) {
      let query = this[`${this.reportStatusActiveName}Query`]
      query.pageIndex = val;
      this.loadData(query);
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item?", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.reportId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              let list = this[`${this.reportStatusActiveName}List`];
              list.splice(index, 1);
            } else {
              this.$message.error(
                "Delete failed, error message: " + res.message
              );
            }
          });
        })
        .catch(() => { });
    },
    publishReport(index, row) {
      // twice to confirm to publish
      this.$confirm(
        "Are you sure to publish this report and against records",
        "Prompt",
        {
          type: "warning",
        }
      )
        .then(() => {
          console.log(row);
          putData(`${this.controllerUrl}/publishReport`, row.reportId).then(
            (res) => {
              if (res.result && res.code === "200") {
                row.status = "Published";
                this.editVisible = false;
                this.$message.success("Publish successfully");

                if (this.reportStatusActiveName == "activeReport") {
                  this.finishedReportList = null;
                } else {
                  this.activeReportList = null;
                }
                this.loadData(this.query);
              } else {
                this.$message.error(
                  "Publish failed, error message: " + res.message
                );
              }
            }
          );
        })
        .catch(() => { });
    },
    async handleEdit(index, row) {
      console.log(row);
      this.dialogTitle = "Edit";
      let res = await getData(
        `${this.controllerUrl}/getWeeklyReportAndRecords/${this.activeRecordType}/${row.reportId}`
      );
      console.log("handleEdit", res);
      if (res.result && res.code === "200") {
        this.form = res.result;
        this.editMode = true;
        this.editVisible = true;
      }
    },
    async manageWeeklyRecords(recordType, index, row) {
      this.manageRecordType = recordType;
      let res = await getData(
        `${this.controllerUrl}/getWeeklyReportAndRecords/${this.manageRecordType}/${row.reportId}`
      );
      console.log("manageWeeklyRecords", res);
      if (res.result && res.code === "200") {
        this.currentReport = res.result;
        this.manageWeeklyRecordsDialogTitle = `Manage Students' ${this.manageRecordType} weekly records for the report '${this.currentReport.name}'`;
        this.showManageWeeklyRecordsDialog = true;
      }
    },
    showWeeklyReport(index, row, recordType) {
      this.currentReport = row;
      this.reportRecordType = recordType;
      this.weeklyRecordReportDialogTitle = `The report for the weekly records '${this.currentReport.name}'`;
      this.showWeeklyRecordReportDialog = true;
    },
    issuePoints(index, row, recordType, action) {
      postData(
        `${this.controllerUrl}/issuePoints/${recordType}/${row.reportId}?action=${action}`
      ).then((res) => {
        if (res.code === "200") {
          if ((res.result || "").length === 0) {
            this.$message.info("No points need to be issued out");
            return;
          }
          if (action === "confirm") {
            this.$msgbox({
              title: "Feedback",
              message: `<div style="height:400px;overflow:auto;text-align:left;">${res.result}</div>`,
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "Close",
              dangerouslyUseHTMLString: true,
              center: true,
              draggable: true,
              customStyle: "width:50%;",
            });
          } else {
            // twice to confirm to issue
            this.$confirm(
              `Please confirm below messages: <br><div style="height:400px;overflow:auto;">${res.result}</div>`,
              "Prompt",
              {
                type: "warning",
                dangerouslyUseHTMLString: true,
                customStyle: "width:50%;",
              }
            ).then(() => {
              this.issuePoints(index, row, recordType, "confirm");
            });
          }
        } else {
          this.$message.error(
            "Issue Points failed, error message: " + res.message
          );
        }
      });
    },
    handleCreate() {
      this.form = {
        grade: 6,
        term: 1,
        status: "Active",
        quizW1RawScore: null,
        quizW2RawScore: null,
        quizW3RawScore: null,
        quizW4RawScore: null,
        quizW5RawScore: null,
        quizW6RawScore: null,
        quizW7RawScore: null,
        quizW8RawScore: null,
        quizW9RawScore: null,
        quizW10RawScore: null,
      };
      this.form.records = {};
      for (let item in WeeklyRecordTypeEnum) {
        let recordType = this.$formatter.toLowerFirstChar(item);
        this.form.records[recordType] = [];
      }
      this.dialogTitle = "New";
      this.editVisible = true;
      this.editMode = false;
    },
    saveRecords() {
      postData(
        `${this.controllerUrl}/UpdateWeeklyRecordsWithPendingStatus/${this.currentReport.reportId}`,
        this.currentReport.records
      ).then((res) => {
        if (res.result && res.code === "200") {
          this.showManageWeeklyRecordsDialog = false;
          this.$message.success(
            "Students' weekly records updated successfully"
          );
          this.form = res.result;
          if (this.reportStatusActiveName == "activeReport") {
            this.finishedReportList = null;
          } else {
            this.activeReportList = null;
          }
          this.loadData(this.query);
        } else {
          this.$message.error("Update failed, error message: " + res.message);
        }
      });
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (this.form.reportId && this.form.reportId.length === 22) {
            putData(this.controllerUrl, this.form.reportId, this.form).then(
              (res) => {
                if (res.result && res.code === "200") {
                  this.editVisible = false;
                  this.$message.success("Update successfully");
                  if (this.reportStatusActiveName == "activeReport") {
                    this.finishedReportList = null;
                  } else {
                    this.activeReportList = null;
                  }
                  this.loadData(this.query);
                } else {
                  this.$message.error(
                    "Update failed, error message: " + res.message
                  );
                }
              }
            );
          } else {
            postData(this.controllerUrl, this.form).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;
                if (this.reportStatusActiveName == "activeReport") {
                  this.finishedReportList = null;
                } else {
                  this.activeReportList = null;
                }
                this.loadData(this.query);
              } else {
                this.$message.error(
                  "Create failed, error message: " + res.message
                );
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    showWeeklyReportStatusTagType(index, row) {
      if (row.status !== "Published") {
        return "info";
      }
      return "success";
    },
    handleRecordTypeTabChange(activeName, oldActiveName) {
      console.log("handleRecordTypeTabChange", activeName, oldActiveName);
      this.form.records[activeName] = this.form.records[activeName] || [];
      if (this.form.reportId && this.form.records[activeName].length === 0) {
        getData(
          `${this.controllerUrl}/getWeeklyRecords/${activeName}/${this.form.reportId}`
        ).then((res) => {
          if (res.result && res.code === "200") {
            this.form.records[activeName] = res.result;
          }
        });
      }

      return true;
    },
    getTableData() {
      return this[`${this.reportStatusActiveName}List`];
    },
    handleReportStatusTabChange(activeName, oldActiveName) {
      console.log("handleTabChange", activeName, oldActiveName);
      let query = this[`${activeName}Query`]
      this.query = query;
      let list = this[`${activeName}List`];
      if (!(list && list.length > 0)) {
        this.loadData(query);
      }
      return true;
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 1.17rem;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
