<template>
  <div class="handle-box">
    <el-input
      v-model="query.studentId"
      placeholder="Student No."
      class="handle-input mr10"
      :clearable="true"
    ></el-input>
    <el-input
      v-model="query.studentName"
      placeholder="Student Name"
      class="handle-input mr10"
      :clearable="true"
    ></el-input>
    <div class="upload-panel">
      <el-button type="primary" class="mr10" @click="handleAddItem(-1)">
        New {{ this.recordTypeText }} grade<el-icon><plus /> </el-icon>
      </el-button>
      <el-upload
        ref="upload"
        class="upload-container"
        :action="getUploadUrl()"
        :on-success="fileUploadSuccess"
        :limit="1"
        :on-exceed="handleExceed"
        :headers="headers"
      >
        <el-button type="primary"> Import {{ this.recordTypeText }} grades </el-button>
      </el-upload>
      <el-button type="primary" @click="exportTemplateFromReport">
        Export {{ this.recordTypeText }} grades template
      </el-button>
    </div>
  </div>
  <el-table
    :data="filterRecords()"
    border
    class="table"
    ref="multipleTable"
    header-cell-class-name="table-header"
    @sort-change="changeSort"
    :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
  >
    <el-table-column
      prop="studentId"
      label="Student No."
      :width="this.$widthRatio * 108 + 'px'"
    >
      <template v-slot="{ row }">
        <el-input
          v-if="row.isNew"
          v-model="row.studentId"
          size="small"
          :clearable="true"
          @change="loadStudentData(row)"
        ></el-input>
        {{ row.isNew ? "" : row.studentId }}
      </template>
    </el-table-column>
    <el-table-column prop="studentName" label="Student Name"></el-table-column>
    <el-table-column label="W1" prop="w1" width="110">
      <template #header> {{ this.getHeaderTitle("w1") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w1"
          size="small"
          :precision="this.getPrecision('w1')"
          :step="1"
          :min="this.getMinScore('w1')"
          :max="this.getMaxScore('w1')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W2" prop="w2" width="110">
      <template #header> {{ this.getHeaderTitle("w2") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w2"
          size="small"
          :precision="this.getPrecision('w2')"
          :step="1"
          :min="this.getMinScore('w2')"
          :max="this.getMaxScore('w2')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W3" prop="w3" width="110">
      <template #header> {{ this.getHeaderTitle("w3") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w3"
          size="small"
          :precision="this.getPrecision('w3')"
          :step="1"
          :min="this.getMinScore('w3')"
          :max="this.getMaxScore('w3')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W4" prop="w4" width="110">
      <template #header> {{ this.getHeaderTitle("w4") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w4"
          size="small"
          :precision="this.getPrecision('w4')"
          :step="1"
          :min="this.getMinScore('w4')"
          :max="this.getMaxScore('w4')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W5" prop="w5" width="110">
      <template #header> {{ this.getHeaderTitle("w5") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w5"
          size="small"
          :precision="this.getPrecision('w5')"
          :step="1"
          :min="this.getMinScore('w5')"
          :max="this.getMaxScore('w5')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W6" prop="w6" width="110">
      <template #header> {{ this.getHeaderTitle("w6") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w6"
          size="small"
          :precision="this.getPrecision('w6')"
          :step="1"
          :min="this.getMinScore('w6')"
          :max="this.getMaxScore('w6')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W7" prop="w7" width="110">
      <template #header> {{ this.getHeaderTitle("w7") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w7"
          size="small"
          :precision="this.getPrecision('w7')"
          :step="1"
          :min="this.getMinScore('w7')"
          :max="this.getMaxScore('w7')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W8" prop="w8" width="110">
      <template #header> {{ this.getHeaderTitle("w8") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w8"
          size="small"
          :precision="this.getPrecision('w8')"
          :step="1"
          :min="this.getMinScore('w8')"
          :max="this.getMaxScore('w8')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W9" prop="w9" width="110">
      <template #header> {{ this.getHeaderTitle("w9") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w9"
          size="small"
          :precision="this.getPrecision('w9')"
          :step="1"
          :min="this.getMinScore('w9')"
          :max="this.getMaxScore('w9')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="W10" prop="w10" width="110">
      <template #header> {{ this.getHeaderTitle("w10") }} </template>
      <template v-slot="{ row }">
        <el-input-number
          v-model="row.w10"
          size="small"
          :precision="this.getPrecision('w10')"
          :step="1"
          :min="this.getMinScore('w10')"
          :max="this.getMaxScore('w10')"
          :controls="false"
          style="width: 90px"
        ></el-input-number>
      </template>
    </el-table-column>
    <el-table-column prop="total" label="Total" :width="this.$widthRatio * 110 + 'px'">
      <template v-slot="{ row }">
        {{ this.getTotal(row) }}
        <div
          v-if="row.points"
          :title="this.getPointsIssuedDescription(row)"
          :style="'color:' + this.getPointsIssuedColor(row) + ';'"
        >
          Points: {{ row.points }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Comments" :width="this.$widthRatio * 180 + 'px'">
      <template v-slot="{ row }">
        <el-input
          type="textarea"
          v-model="row.comment"
          maxlength="2000"
          show-word-limit
        ></el-input>
      </template>
    </el-table-column>
    <el-table-column
      prop="updatedTime"
      :formatter="$tableCellFormatter.formatDate"
      label="Updated Time"
      :width="this.$widthRatio * 100 + 'px'"
    ></el-table-column>
    <el-table-column label="Operation" width="110" align="center">
      <template #default="scope">
        <el-button v-if="false" @click="handleAddItem(scope.$index, scope.row)">
          <el-icon>
            <plus />
          </el-icon>
        </el-button>
        <el-button @click="handleDeleteItem(scope.$index, scope.row)">
          <el-icon> <delete /> </el-icon>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { getData, downloadFile } from "../../service/api";
import { WeeklyRecordTypeEnum } from "../../service/models/enums.js";

export default {
  name: "manageWeeklyRecords",
  props: {
    recordType: String,
    reportData: Object,
    recordList: Array,
  },
  data() {
    return {
      controllerUrl: "/weeklyReport",
      maxGradePerWeek: 5,
      weeklyHomeworkPointsPerScore: 4,
      query: {
        reportId: null,
        studentId: null,
        studentName: null,
        recordType: "Homework",
        orderBy: "StudentId",
        orderDirection: "ASC",
      },
      precisionForRecordType: 0,
      recordTypeText: "",
      maxScore: Infinity,
      headers: { authorization: `Bearer ${this.$user.getAccessToken()}` },
      tableData: [],
    };
  },
  watch: {
    recordList(newValue) {
      console.log("watch.recordList", newValue);
      if (newValue) {
        if (newValue !== this.tableData) {
          this.initAndLoadData(this.reportData, newValue, this.recordType);
        }
      }
    },
    recordType(newValue) {
      console.log("watch.recordType", newValue);
      if (newValue) {
        if (newValue !== this.query.recordType) {
          this.initAndLoadData(this.reportData, this.recordList, newValue);
        }
      }
    },
  },
  created() {
    this.maxGradePerWeek = this.$appSetting.getGenericValue(
      `MAX_GRADE_PER_WEEK_FOR_${this.recordType.toUpperCase()}`,
      5
    );
    this.weeklyHomeworkPointsPerScore = this.$appSetting.getGenericValue(
      `WEEKLY_${this.recordType.toUpperCase()}_POINTS_PERSCORE`,
      1
    );

    this.initAndLoadData(this.reportData, this.recordList, this.recordType);
  },
  methods: {
    initAndLoadData(reportData, recordList, recordType) {
      if (recordType === WeeklyRecordTypeEnum.Homework) {
        this.precisionForRecordType = 1;
        this.recordTypeText = "homework";
        this.maxScore = Infinity;
      } else {
        this.precisionForRecordType = 0;
        this.recordTypeText = "quiz";
        this.maxScore = Infinity;
      }
      console.log("initAndLoadData", reportData, recordList);
      this.sortListByStudentName(recordList);
      this.query.reportId = reportData.reportId;
      this.query.recordType = recordType;
      this.tableData = this.reconstructData(recordList);
    },
    importRecords() {
      if (this.$refs.upload.uploadFiles.length <= 0) {
        this.$message.error("Please select a file to go on!");
        return false;
      }
      this.$refs.upload.submit();
    },
    exportTemplateFromReport() {
      if (this.query.reportId) {
        downloadFile(
          "GET",
          `${this.controllerUrl}/exportTemplateFromReportRecords/${this.recordType}/${this.query.reportId}`
        ).catch((err) => {
          this.$message.error(
            "Download report-records template failed, error message: " + err
          );
        });
      } else if (this.reportData.roomId) {
        downloadFile(
          "GET",
          `${this.controllerUrl}/exportTemplateFromClassRoom/${this.recordType}/${this.reportData.roomId}`
        ).catch((err) => {
          this.$message.error(
            "Download report-records template failed, error message: " + err
          );
        });
      }
    },
    fileUploadSuccess(response) {
      console.log(response);
      this.$refs.upload.clearFiles();

      if (response.result) {
        let list = response.result;
        if (this.tableData != null && this.tableData.length > 0) {
          let recordList = this.tableData;
          for (let i = 0; i < list.length; i++) {
            let record = list[i];
            let index = recordList.findIndex((element) => {
              return element.studentId === record.studentId;
            });
            if (index >= 0) {
              recordList[index] = record;
            } else {
              recordList.push(record);
            }
          }
        } else {
          this.tableData = list;
        }

        this.$message({
          message: "Import students' marks successfully, but not save to the database",
          type: "success",
        });
      } else {
        this.$message.error(response.message);
      }
    },
    handleExceed(files) {
      this.$refs.upload.clearFiles();
      this.$refs.upload.handleStart(files[0]);
    },
    getUploadUrl() {
      return `${this.$appSetting.getAPIRoot()}${this.controllerUrl}/importRecords/${
        this.recordType
      }/${this.reportData.reportId || ""}`;
    },
    loadStudentData(row) {
      console.log("loadStudentData", row);
      if (!row.studentId) return;
      if (this.tableData && this.tableData.length > 0) {
        if (
          this.tableData.findIndex((t) => t !== row && t.studentId === row.studentId) >= 0
        ) {
          this.$message.warning(`The student ${row.studentId} is already exist.`);
          row.studentId = "";
          row.isNew = true;
          return;
        }
      }
      if (row.studentId && row.studentId.length === 8) {
        getData(`/student/${row.studentId}`, null)
          .then((res) => {
            if (res.result && res.code === "200") {
              row.isNew = false;
              row.studentName = res.result.username;
            } else {
              this.$message.error(
                "Fetch student data failed, error message: " + res.message
              );
            }
          })
          .catch(() => {});
      }
    },
    filterRecords() {
      let recordList = this.tableData;
      if (recordList && recordList.length > 0) {
        recordList = recordList.filter((row) => {
          return (
            (!this.query.studentId || this.query.studentId === row.studentId) &&
            (!this.query.studentName ||
              row.studentName
                .toLowerCase()
                .includes(this.query.studentName.toLowerCase()))
          );
        });
      }

      return recordList;
    },
    reconstructData(recordList) {
      if (recordList && recordList.length > 0) {
        for (let i = 0; i < recordList.length; i++) {
          recordList[i].isNew = false;
        }
      }

      return recordList;
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
    },
    handleAddItem(index) {
      this.tableData.splice(index + 1, 0, {
        isNew: true,
        recordType: this.recordType,
        w1: 0,
        w2: 0,
        w3: 0,
        w4: 0,
        w5: 0,
        w6: 0,
        w7: 0,
        w8: 0,
        w9: 0,
        w10: 0,
        total: 0,
      });
    },
    handleDeleteItem(index, row) {
      let pos = this.tableData.findIndex((e) => e.studentId === row.studentId);
      if (pos >= 0) this.tableData.splice(pos, 1);
    },
    handleMoveUpItem(index) {
      if (index <= 0) return;
      let delItems = this.tableData.splice(index, 1);
      this.tableData.splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownItem(index) {
      if (index >= this.tableData.length - 1) return;
      let delItems = this.tableData.splice(index, 1);
      this.tableData.splice(index + 1, 0, delItems[0]);
    },
    getTotal(row) {
      row.total = (row.w1 ||
      row.w2 ||
      row.w3 ||
      row.w4 ||
      row.w5 ||
      row.w6 ||
      row.w7 ||
      row.w8 ||
      row.w9 ||
      row.w10
        ? (row.w1 || 0) +
          (row.w2 || 0) +
          (row.w3 || 0) +
          (row.w4 || 0) +
          (row.w5 || 0) +
          (row.w6 || 0) +
          (row.w7 || 0) +
          (row.w8 || 0) +
          (row.w9 || 0) +
          (row.w10 || 0)
        : 0
      ).toFixed(this.getPrecision());
      row.points = Math.round(row.total * this.weeklyHomeworkPointsPerScore);

      return row.total;
    },
    getTotalForSpecialWeek(columnName) {
      let total = 0;
      let recordList = this.tableData;
      if (recordList && recordList.length > 0) {
        for (let i = 0; i < recordList.length; i++) {
          total += parseFloat(recordList[i][columnName]);
        }
      }

      return total;
    },
    getAvgForSpecialWeek(columnName) {
      let avg = 0;
      let total = 0;
      let recordList = this.tableData;
      if (recordList && recordList.length > 0) {
        for (let i = 0; i < recordList.length; i++) {
          total += parseFloat(recordList[i][columnName]);
        }
        avg = total / recordList.length;
      }

      return avg;
    },
    sortListByStudentName(recordList) {
      if (recordList && recordList.length) {
        recordList.sort((a, b) => {
          if (a.studentName < b.studentName) {
            return -1;
          }
          if (a.studentName > b.studentName) {
            return 1;
          }
          return 0;
        });
      }
    },
    getPrecision() {
      if (this.recordType === WeeklyRecordTypeEnum.Homework) {
        return 1;
      } else {
        return 1;
      }
    },
    getHeaderTitle(week) {
      let w = this.$formatter.toUpperFirstChar(week);
      if (this.recordType === WeeklyRecordTypeEnum.Homework) {
        return w;
      } else {
        let scoreFieldName = `quiz${w}RawScore`;
        return `${w}(${this.reportData[scoreFieldName]})`;
      }
    },
    getMaxScore(week) {
      let w = this.$formatter.toUpperFirstChar(week);
      if (this.recordType === WeeklyRecordTypeEnum.Homework) {
        return this.maxScore;
      } else {
        let scoreFieldName = `quiz${w}RawScore`;
        return this.reportData[scoreFieldName];
      }
    },
    getMinScore() {
      if (this.recordType === WeeklyRecordTypeEnum.Homework) {
        return -3;
      } else {
        return 0;
      }
    },
    getPointsIssuedDescription(row) {
      if (row.pointsIssued) {
        if (row.points > 0) {
          return `${row.studentName} received ${row.points} points.`;
        } else {
          return `${row.studentName} deducted ${row.points} points.`;
        }
      } else {
        if (row.points > 0) {
          return `${row.studentName} will receive ${row.points} points next term.`;
        } else {
          return `${row.studentName} will get deducted ${row.points} points next term.`;
        }
      }
    },
    getPointsIssuedColor(row) {
      if (row.pointsIssued && row.pointsIssued === 1) {
        if (row.points > 0) {
          return "#000000";
        } else {
          return "#FF0000";
        }
      }
      if (row.points > 0) {
        return "#0000FF";
      } else {
        return "#FF0000";
      }
    },
  },
};
</script>

<style scoped>
.upload-panel {
  float: right;
}
.upload-panel .upload-container {
  float: left;
  margin-right: 10px;
}
.upload-panel:after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}
.handle-box {
  margin-bottom: 20px;
}
.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
  margin-top: 30px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.el-table td,
.el-table th {
  padding: 12px;
}
.el-table .cell {
  padding-left: 0;
  padding-right: 0;
}
.el-input-number--small {
  width: 100%;
}
</style>
<style>
.upload-panel .el-upload--text {
  width: auto;
  height: auto;
  border-width: 0px;
}
</style>
